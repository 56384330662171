<template>
	<v-list-item three-line :value="value" v-if="user">
		<v-list-item-content>
			<v-row>
				<v-col class="pt-1 pb-1">
					<v-list-item-title>{{ user.displayName }}</v-list-item-title>
					<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
					<v-list-item-subtitle v-if="user.updated_at">Active: 
						{{
							user.updated_at.toDate() | moment("Do MMMM YYYY")
						}}
					</v-list-item-subtitle>
				</v-col>
				<v-col class="pt-1 pb-1" v-if="poll.status == 'submitted' ">
                    <v-chip class="mw-chip">
						{{answer}}
					</v-chip>
                </v-col>
			</v-row>
		</v-list-item-content>
		<v-list-item-action>
			<remind-colleague v-model="user.id" icon></remind-colleague>
		</v-list-item-action>
	</v-list-item>
</template>

<style lang="less">
.mw-chip {
	font-size: 11px !important;
	padding: 2px 8px;	
}
</style>
<script>
import RemindColleague from "@c/collaboration/RemindColleague";
export default {
	name: "PolleeListItem",
	props: {
        value: { type: String },
	},
	components: {
		RemindColleague,
	},
	computed: {
        poll(){
            return this.$store.state.polls.data[this.value];
        },
		user() {
            if( !this.poll ){
                return
            }
			return this.$store.state.users.data[this.poll.user];
		},
		question(){
            if( !this.poll ){
                return
            }
			return this.$store.state.questions.data[this.poll.question]
		},
		questionTemplate(){
            if( !this.question ){
                return;
			}
			return this.$store.state.questionTemplates.data[this.question.question_template]
		},
        answer(){
			if( !this.questionTemplate || !this.poll.data ){
				return;
			}
            return this.questionTemplate.answer_options[this.poll.data[this.poll.question]].text
        },
		initials() {
			return this.user.displayName
				.split(" ")
				.map((a) => a.charAt(0))
				.join("");
		},
	},
};
</script>
