<template>
	<v-card flat>
		<v-divider class="mt-2 mb-5" v-if="!hideTitle"></v-divider>
		<v-toolbar
			flat
			class="mw-question-toolbar mt-5"
			:collapse="hideTitle"
			:absolute="hideTitle"
			:style="hideTitle ? rightStyle : {}"
		>
			<span v-if="!hideTitle" v-html="`${questionTitle}`"></span>

			<v-spacer></v-spacer>
			<template v-if="tooltip">
				<v-tooltip left allow-overflow max-width="280">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on">mdi-information-outline</v-icon>
					</template>
					{{ tooltip }}
				</v-tooltip>
			</template>
			<div class="ml-4">
				<template v-if="canPoll">
					<poll :id="id"></poll>
				</template>
			</div>
		</v-toolbar>
		<v-card-text v-if="questionContent.length">
			<div v-html="questionContent"></div>
		</v-card-text>
	</v-card>
</template>

<style lang="less">
.mw-question-toolbar {
	@media screen and (min-width: 768px) {
		font-size: 1.1em;
	}
	@media screen and (max-width: 768px) {
		font-size: 0.8em;
		margin-top: 20px;
		margin-bottom: 60px;
	}
	margin-bottom: 15px;
	.v-toolbar__content {
		padding-left: 0px !important;
	}
	span {
		max-width: 90%;
	}
}
</style>
<script>
import Poll from "@c/collaboration/Poll";
export default {
	name: "QuestionTitle",
	props: {
		id: { type: String },
		staffSurvey: { type: Boolean },
		poll: { type: Boolean },
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		canPoll() {
			return this.$store.getters.canPoll && !this.question.hide_polling;
		},
		questionTemplate() {
			if (!this.question) {
				return;
			}
			var id = this.question.question_template;
			return this.$store.state.questionTemplates.data[id];
		},
		questionTexts() {
			return this.$store.getters["questions/getQuestionTexts"](
				this.id,
				this.staffSurvey
			);
		},
		titleKey() {
			if (this.questionTexts.templateTitle) {
				return "templateTitle";
			}
			if (this.questionTexts.content) {
				return "content";
			}
			return "indicator";
		},
		hideTitle() {
			if( this.poll ){
				return false;
			}
			if (this.titleKey == "templateTitle") {
				if (this.questionTemplate.hide_title) {
					return true;
				}
			}
			if (this.question.hide_content) {
				return true;
			}
			if (!this.questionTitle || !this.questionTitle.length) {
				return true;
			}
			return false;
		},
		questionTitle() {
			return this.questionTexts[this.titleKey];
		},
		questionContent() {
			const self = this;
			let keys = Object.keys(self.questionTexts);
			let exclude = ["string", self.titleKey];
			if (self.question.hide_content) {
				exclude.push("content");
			}
			if (self.questionTemplate.hide_title) {
				exclude.push("templateTitle");
			}
			let include = keys.filter((a) => !exclude.includes(a));
			return include
				.filter((a) => self.questionTexts[a])
				.map((a) => self.questionTexts[a])
				.join("<br/>");
		},
		tooltip() {
			return this.question.help_text || false;
		},
		rightStyle() {
			return {
				right: 0,
			};
		},
	},
	components: {
		Poll,
	},
};
</script>
