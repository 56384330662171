<template>
	<mw-dialog
		v-model="dialog"
		submitBtn
		@submit="close"
		:title="$t('collaboration.polls.title')"
	>
		<template v-slot:button>
			<v-tooltip left>
				<template v-slot:activator="{on}">
					<v-btn v-on="on" icon @click="dialog = true">
						<v-icon>mdi-comment-account</v-icon>
						<v-badge
							v-if="polls.length"
							small
							color="green"
							:content="polls.length"
						></v-badge>
					</v-btn>
				</template>
					{{$t('collaboration.polls.title')}}
			</v-tooltip> 
		</template>
		<template v-slot:dialog-body>
			<div v-html="$t('collaboration.polls.before_poll')"></div>
			<div v-html="$t('collaboration.polls.poll_participants')"></div>
			<v-list dense>
				<pollee-list-item
					v-for="poll in polls"
					:key="poll.id"
					v-model="poll.id"
				>
						
				</pollee-list-item>
			</v-list>
			<div v-html="$t('collaboration.polls.add_participant')"></div>
			<add-colleague @input="addPoll"></add-colleague>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import AddColleague from "./AddColleague.vue";
import PolleeListItem from "./PolleeListItem.vue";
export default {
	name: "Poll",
	props: {
		id: { type: String }, // the ID of the question
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		polls() {
			return this.$store.getters["polls/byQuestion"](this.id);
		},
	},
	components: {
		AddColleague,
		MwDialog,
		PolleeListItem,
	},
	methods: {
		close() {
			this.dialog = false;
		},
		addPoll(value) {
			this.$store.dispatch("polls/new", { question: this.id, user: value });
		},
	},
};
</script>
